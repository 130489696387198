*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* background-color: #F5F5F5;  */
  /*background color of all website */
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #D9D9D9; 
  border-radius: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3EDC84; 
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
